import DateFilters from '@/filters/dateFilters'
import CurrencyFilters from '@/filters/currencyFilters'
import IDFilters from '@/filters/idFilters'

const Filters = {
  date: DateFilters,
  currency: CurrencyFilters,
  id: IDFilters,
}

export default Filters
