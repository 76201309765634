/* eslint-disable */

import '@/@fake-db/db'
import '@/@iconify/icons-bundle'
import App from '@/App.vue'
import ability from '@/plugins/casl/ability'
import layoutsPlugin from '@/plugins/layouts'
import vuetify from '@/plugins/vuetify'
import {loadFonts} from '@/plugins/webfontloader'
import '@/styles/styles.scss'
import '@core/scss/template/index.scss'
import {abilitiesPlugin} from '@casl/vue'
import {createPinia} from 'pinia'
import {createApp, markRaw} from 'vue'
import router from '@/router'
import Filters from '@/filters'
import VueChatScroll from 'vue3-chat-scroll-next'
import 'dashicons/dashicons.css'
import './assets/styles/index.scss'
import '@vuepic/vue-datepicker/dist/main.css'
import HighchartsVue from 'vue3-highcharts'
import Highcharts from 'highcharts'
import stockInit from 'highcharts/modules/stock'
import mapInit from 'highcharts/modules/map'
import {CustomDirectives} from "@/plugins/customDirectives";

loadFonts()

// Create vue app
const app = createApp(App)
// Setup Plugins
const pinia = createPinia()

pinia.use(({ store }) => {
  store.$pinia = markRaw(pinia)
  store.$router = markRaw(router)
})
// Use plugins
app.use(vuetify)
app.use(pinia)
app.use(router)
app.use(layoutsPlugin)
app.use(abilitiesPlugin, ability, {
  useGlobalProperties: false,
})
app.use(VueChatScroll)
app.use(HighchartsVue)
// app.use(VueReCaptcha, {
//   siteKey: import.meta.env.VITE_RECAPTCHA_SITE_KEY,
//   loaderOptions: {
//     autoHideBadge: true, // Optional: Hides the badge
//     explicitRenderParameters: {
//       badge: 'bottomleft',
//     },
//   },
// })
// Customer Directive Registrar Plugin
app.use(CustomDirectives)
stockInit(Highcharts)
mapInit(Highcharts)
// Mount filters
app.config.globalProperties.$filters = Filters

// Sentry Error Tracking
//initSentry(app, ['development'])
// Mount vue app
app.mount('#app')
