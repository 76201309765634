import axiosIns from '@axios'
import { isBionicSite } from '@/utils/whiteLabelHelpers'
import { StorageKeysEnum } from '@/common/enums/storageKeysEnum'
import { useUserStore } from '@/stores/user'

export default class StripeService {
  static async getPlans(selectedProvider?: string) {
    const userStore = useUserStore()
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        let url =
          isBionicSite() &&
          !localStorage.getItem(StorageKeysEnum.WL_OWNER_VISITING_CUSTOMER) &&
          !userStore.wlVisitingCustomer
            ? 'stripe/plans'
            : 'white-label/payments/customers/plans'
        if (selectedProvider) url += `?provider=${selectedProvider}`
        const { data } = await axiosIns.get(url)
        resolve(data?.data)
      } catch (e) {
        reject(e)
      }
    })
  }
  static async getPublicPlans(selectedProvider?: string): Promise<any> {
    const userStore = useUserStore()
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        let url =
          isBionicSite() &&
          !localStorage.getItem(StorageKeysEnum.WL_OWNER_VISITING_CUSTOMER) &&
          !userStore.wlVisitingCustomer
            ? 'stripe/public/plans'
            : 'white-label/payments/customers/plans'
        if (selectedProvider) url += `?provider=${selectedProvider}`
        const { data } = await axiosIns.get(url)
        resolve(data?.data)
      } catch (e) {
        reject(e)
      }
    })
  }

  static async getProratedPrice(
    priceIds: string[],
    custom_plan: boolean,
    fist_purchase: boolean,
  ) {
    const { data } = await axiosIns.post('/site/invoice/prorated', {
      price_ids: priceIds,
      is_custom_plan: custom_plan,
      is_fist_purchase: fist_purchase,
    })

    return data?.data?.prorated_amount
  }

  static async updatePaymentMethod() {
    const { data } = await axiosIns.get('/user-stripe/add-payment-method')
    return data?.data
  }

  static async getPaymentMethods() {
    const { data } = await axiosIns.get('/user-stripe/payment-methods')
    return data?.data
  }

  static async defaultPaymentMethod(id: any) {
    const { data } = await axiosIns.put(
      `/user-stripe/default-payment-method/${id}`,
    )
    return data?.data
  }

  static async deletePaymentMethod(id: any) {
    const { data } = await axiosIns.delete(`/user-stripe/payment-method/${id}`)
    return data?.data
  }
}
