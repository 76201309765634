import { v4 } from 'uuid'

const IDFilters = {
  gen: {
    v4: () => {
      return v4()
    },
  },
}

export default IDFilters
